import React,{useState} from 'react';
import './navbar.css';
import{RiMenu3Line,RiCloseLine} from 'react-icons/ri'
import logo from '../../assets/logo.png'
import './navbar.css'


const Menu=()=>(
  <>
  <p><a href="#Početna">Početna</a></p>
          <p><a href="#O-nama">O nama</a></p>
          <p><a href="#Kontakt">Kontakt</a></p>
          <p><a href="#Usluge">Usluge</a></p>
          
          </>
)

const Navbar = () => {
  const [toggleMenu,setToggleMenu]=useState(false)
  return (
    
    <div className='gpt3__navbar'>
      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
           <img src={logo} alt="logo"/>
        </div>
        <div className='gpt3__navbar-links_container'>
          <p><a href="#Početna">Početna</a></p>
          <p><a href="#O-nama">O nama</a></p>
          <p><a href="#Kontakt">Kontakt</a></p>
          <p><a href="#Usluge">Usluge</a></p>
          
        </div>
      </div>
      <div className='gpt3__navbar-menu'>
      {toggleMenu
      ?<RiCloseLine color='#000' size={27} onClick={()=> setToggleMenu(false)}/>
      :<RiMenu3Line color='#000' size={27} onClick={()=> setToggleMenu(true)}/>
    }
    {
    toggleMenu &&(
    <div className='gpt3__navbar-menu_container'>
      <div className='gpt3__navbar-menu_container-links'>
        <Menu/>
      </div>
    </div> )
    }
  </div>
  <div className='back'></div>
    </div>
  )
}

export default Navbar